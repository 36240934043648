import * as THREE from 'three';
import Experience from './Experience';

export default class VideoSphere {
    constructor() {
        this.experience = new Experience();
        this.scene = this.experience.scene;
        this.videoElement = this.experience.video;
        this.videoTexture = new THREE.VideoTexture(this.videoElement)
        this.videoTexture.colorSpace = THREE.SRGBColorSpace;
        this.aspectRatio = this.videoTexture.image.videoWidth / this.videoTexture.image.videoHeight;

        if (this.experience.isXRActive()) {
          for (let [eye, rotate180] of [[false, false], [true, false], [false, true], [true, true]]) {
              const mesh = this.createMesh(true, rotate180, !eye);
              if (mesh) {
                  mesh.rotation.y = Math.PI;
                  mesh.layers.set(eye ? 1 : 2);
                  this.scene.add(mesh);
              }
          }
      } else {
          const mesh = this.createMesh(true, false, 0);
          mesh.rotation.y = Math.PI;
          this.scene.add(mesh);
      }
    }
    createMesh(isStereoscopic, mirrorBack, rightEye = false) {
      if (isStereoscopic && mirrorBack) { return }
      const geometry = new THREE.SphereGeometry(100, 80, 60, mirrorBack ? Math.PI : 0, Math.PI * (isStereoscopic ? 1 : 2));
      geometry.scale(-1, 1, 1);
      const uvs = geometry.attributes.uv.array;
      if (isStereoscopic) {
          for (let j = 0; j < uvs.length; j += 2) {
              if (mirrorBack && (j % 2 == 0)) uvs[j] *= -1;
              uvs[j] *= 0.5;
              // uvs[j] = (uvs[j] - 0.5) * this.aspectRatio + 0.5;
              // uvs[j] = ((uvs[j] * 2) - 1) * this.aspectRatio * 0.5 + 0.5;
              uvs[j] = Math.max(0, Math.min(1, uvs[j]));
              if (rightEye) uvs[j] += 0.5;
          }
      }
      const material = new THREE.MeshBasicMaterial({ map: this.videoTexture });
      return new THREE.Mesh(geometry, material);
    }
    update() {
        this.videoTexture.update();
    }
}