import * as THREE from "three";
import Experience from "./Experience.js";

export default class VideoPlayer {
  constructor() {
    this.experience = new Experience();
    this.scene = this.experience.scene;
    this.video = this.experience.video;
  }
  toggleMute() {
    this.video.muted = !this.video.muted 
  }
  togglePlay() {
    return (this.video.paused) ? this.video.play() : this.video.pause();
  }
  update() {}
}
